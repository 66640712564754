<template>
  <div class="activity-header">
    <adminNav>
      <router-link to="/private/clients">
        Клиенты
      </router-link>
      <router-link to="/private/activity/settings">
        Виды деятельности
      </router-link>
      <router-link to="/private/logs">
        Просмотр логов
      </router-link>
      <router-link to="/private/other/settings">
        Прочие настройки
      </router-link>
    </adminNav>
  </div>
  <div class="create-button">
    <xButton @click="$router.push('/private/activity/bonuses')">
      <icon class="xbutton-icon" name="create"/>
      Создать
    </xButton>
  </div>
  <div class="private-table">
    <xTable v-bind="tableData">
      <template  v-slot="{ records }" >
        <td>
          <div class="table-action pr">
            <router-link class="custom-note-wrap" :to="'/private/activity/bonuses/' + records.id">
              <icon name="edit" class="table-icon" />
              <div class="custom-note">
                Редактирование вида деятельности не повлияет на ранее созданные компании с таким видом деятельности
              </div>
            </router-link>
            <button class="custom-note-wrap" @click="beforeDelete(records.id)">
              <icon name="delete" class="table-icon" />
              <div class="custom-note">
                Удаление вида деятельности никак не повлияет на ранее созданные компании с таким видом деятельности
              </div>
            </button>
          </div>
        </td>
      </template>
    </xTable>
  </div>
  <pop-up :open="popDelete" @close="popDelete = !popDelete" sizer="pop-verysmall" :privateView="true">
    <h2 class="x-pop-title">Вы удаляете вид деятельности</h2>
    <p class="x-pop-desc-black">
      После удаления он перестанет отображаться в списке видов деятельности, не будет доступен для выбора.
      <br>
      Подтвердите удаление.
    </p>
    <div class="x-pop-footer">
      <xButton @click="onDelete">Удалить</xButton>
      <button @click="closeDelete" class="link x-pop-back">Отменить</button>
    </div>
  </pop-up>
  <scrollButton target=".private-table .x-table" direction="left"/>
  <scrollButton target=".private-table .x-table" direction="right"/>
</template>

<script>
import xTable from '@/components/xTable'
import Icon from '@/components/icons/icon'
import xButton from '@/components/xButton'
import popUp from '@/components/popUp'
import adminNav from '@/components/adminNav'
import scrollButton from '@/components/scrollButton'

export default {
  name: 'vPrivateActivitySettings',
  components: {
    Icon,
    xTable,
    xButton,
    adminNav,
    popUp,
    scrollButton
  },
  emits: ['titleName'],
  data () {
    return {
      popDelete: false,
      deleteId: false,
      tableData: {
        theads: 1,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        privateActivitySettings: true,
        optionsX: [
          {
            name: 'Вид деятельности',
            value: 'activity',
            id: 0
          },
          {
            name: 'Приветственные бонусы',
            value: 'introNumber',
            id: 1
          },
          {
            name: 'Статусы',
            value: 'status1Name',
            id: 2
          },
          {
            name: 'Реферальная программа',
            value: 'refBonuses',
            id: 3
          },
          {
            name: 'Когда долго нет покупок',
            value: 'comebackBonus',
            id: 4
          },
          {
            name: 'Процент от счета бонусами',
            value: 'bonusPercent',
            id: 5
          },
          {
            name: 'На день рождения',
            value: 'birthdayBonus',
            id: 6
          },
          {
            name: 'Сгорание бонусов на счете',
            value: 'bonusesDelete',
            id: 7
          },
          {
            name: 'Начислять бонусы по номеру телефона',
            value: 'bonusesUsePhone',
            id: 8
          },
          {
            name: 'Бонусы доступны после покупки',
            value: 'afterPurchase',
            id: 9
          },
          {
            name: 'Подозрительная активность',
            value: 'alertDay',
            id: 10
          },
          {
            name: 'Дней с посл. покупки, после которой считать клиента потерянным',
            value: 'lostClientDays',
            id: 11
          }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Вид деятельности', 'Приветственные бонусы', 'Статусы', 'Реферальная программа', 'Когда долго нет покупок', 'Процент от счета бонусами', 'На день рождения', 'Сгорание бонусов на счете', 'Начислять бонусы по номеру телефона', 'Бонусы доступны после покупки', 'Подозрительная активность', 'Дней с посл. покупки, после которой считать клиента потерянным'],
        namesForHeaderX: []
      }
    }
  },
  methods: {
    /**
     * example pluralize(15, [1, 2, 5])
     * example pluralize(15, ['адвокат', 'адвоката', 'адвокатов'])
     */
    pluralize (count, words) {
      const countN = parseInt(count)
      const cases = [2, 0, 1, 1, 1, 2]
      return count.toString() + ' ' + words[(countN % 100 > 4 && countN % 100 < 20) ? 2 : cases[Math.min(countN % 10, 5)]]
    },
    beforeDelete (id) {
      this.popDelete = true
      this.deleteId = id
    },
    closeDelete () {
      this.popDelete = false
      this.deleteId = false
    },
    onDelete () {
      if (this.deleteId) {
        fetch(window.privateHost + 'activities/' + this.deleteId, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          const findedI = this.tableData.data.findIndex(el => el.id === this.deleteId)
          this.tableData.data.splice(findedI, 1)

          this.closeDelete()
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    }
  },
  created () {
    this.$emit('titleName', 'Кабинет администратора системы')

    fetch(window.privateHost + 'activities', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const mappingKeys = data.data.map(el => {
        const statusesToList = {}
        el.statuses.map((status, i) => {
          const bonusSplit = status.bonus ? status.bonus.toString().split('.') : ['']
          if (bonusSplit.length === 2 && bonusSplit[1].length === 1) {
            bonusSplit[1] += '0'
          }
          const collectBonus = bonusSplit.join('.')

          statusesToList[`status${i + 1}Name`] = status.name
          statusesToList[`status${i + 1}Bonus`] = collectBonus ? `${collectBonus}%` : ''
          statusesToList[`status${i + 1}From`] = status.sum ? `${status.sum} руб.` : ''
          statusesToList[`status${i + 1}ReccomendationFrom`] = status.recommendCount || ''

          return statusesToList
        })

        const percentCanUseSplit = el.percentCanUse ? el.percentCanUse.toString().split('.') : ['0']
        if (percentCanUseSplit.length === 2 && percentCanUseSplit[1].length === 1) {
          percentCanUseSplit[1] += '0'
        }
        const collectPercentCanUseSplit = percentCanUseSplit.join('.')

        const referralLevel1Split = el.referralLevel1 ? el.referralLevel1.toString().split('.') : ['0']
        if (referralLevel1Split.length === 2 && referralLevel1Split[1].length === 1) {
          referralLevel1Split[1] += '0'
        }
        const collectReferralLevel1Split = referralLevel1Split.join('.')

        const referralLevel2Split = el.referralLevel2 ? el.referralLevel2.toString().split('.') : ['0']
        if (referralLevel2Split.length === 2 && referralLevel2Split[1].length === 1) {
          referralLevel2Split[1] += '0'
        }
        const collectReferralLevel2Split = referralLevel2Split.join('.')

        const referralLevel3Split = el.referralLevel3 ? el.referralLevel3.toString().split('.') : ['0']
        if (referralLevel3Split.length === 2 && referralLevel3Split[1].length === 1) {
          referralLevel3Split[1] += '0'
        }
        const collectReferralLevel3Split = referralLevel3Split.join('.')

        return {
          id: el.id,
          activity: el.name,
          introNumber: el.greetBonus,
          introTimer: parseInt(el.greetBonusDays) === 1 ? 'сразу' : 'не сразу',
          introDelete: parseInt(el.greetBonusBurn) === 0 ? 'не сгорают' : `сгорают через ${el.greetBonusBurnDays} дн.`,

          ...statusesToList,

          refBonuses: el.recommendBonus + '<div class="private-table-desc">' + (parseInt(el.recommendBonusAward) === 0 ? ' за первую покупку' : ' за каждую покупку') + '</div>',
          refProgram: [
            collectReferralLevel1Split ? `${collectReferralLevel1Split}%` : '',
            collectReferralLevel2Split ? `${collectReferralLevel2Split}%` : '',
            collectReferralLevel3Split ? `${collectReferralLevel3Split}% ` : ''
          ].join(' /') +
            '<div class="private-table-desc">' +
            (parseInt(el.referralAward) === 0 ? 'за первую покупку' : 'за каждую покупку') +
            '</div>',
          comebackBonus: el.noShoppingBonus,
          comebackTime: `${el.noShoppingBonusDays} дн.`,
          comebackDelete: parseInt(el.noShoppingBonusBurn) === 0 ? 'не сгорают' : `сгорают через ${el.noShoppingBonusBurnDays} дн.`,
          bonusPercent: collectPercentCanUseSplit ? `${collectPercentCanUseSplit}%` : '',
          birthdayBonus: el.birthdayBonus,
          birthdayTimer: el.birthdayBonusDays,
          birthdayDelete: parseInt(el.birthdayBonusBurn) === 0 ? 'не сгорают' : `сгорают через ${el.birthdayBonusBurnDays} дн.`,
          bonusesDelete: parseInt(el.bonusBurn) === 0 ? 'Нет' : `Сгорают через ${el.bonusBurnDays} дн.`,
          bonusesUsePhone: parseInt(el.usePhone) === 0 ? 'Нет' : 'Да',
          afterPurchase: parseInt(el.bonusAfterBuy) === 1 ? 'Сразу' : 'Не сразу',
          afterPurchaseTimer: parseInt(el.bonusAfterBuy) !== 1 ? `${el.bonusAfterBuyHours} ч.` : false,
          alertDay: el.suspicionPurchaseDay,
          alertWeek: el.suspicionPurchaseWeek,
          alerMonth: el.suspicionPurchaseMonth,
          recDay: el.suspicionRecommendDay,
          recWeek: el.suspicionRecommendWeek,
          recMonth: el.suspicionRecommendMonth,
          lostClientDays: el.clientLostDays
        }
      })

      this.tableData.data.push(...mappingKeys)
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="../vPrivate/vPrivate.scss" scoped/>
<style lang="scss" src="./vPrivateActivitySettings.scss" scoped/>
