<template>
  <div v-show="isVisible" class="scroll" :class="direction" @click="clickAction"/>
</template>

<script>
export default {
  name: 'scrollButton',
  props: {
    target: {
      type: String
    },
    direction: {
      validator (value) {
        return ['left', 'right'].includes(value)
      }
    }
  },
  data () {
    return {
      isVisible: false,
      intervalId: null
    }
  },
  methods: {
    clickAction () {
      const $el = document.querySelector(this.target)

      const changeScroll = this.direction === 'right' ? $el.clientWidth : -$el.clientWidth
      const inc = this.direction === 'right' ? -250 : 250

      document.querySelector(this.target).scrollTo({
        left: $el.scrollLeft + changeScroll + inc,
        behavior: 'smooth'
      })
    },
    checkVisible () {
      const $el = document.querySelector(this.target)
      if (!$el) {
        return false
      }
      if (this.direction === 'left') {
        this.isVisible = $el.scrollLeft !== 0
      } else {
        this.isVisible = ($el.scrollLeft + $el.clientWidth) < $el.scrollWidth
      }
    }
  },
  mounted () {
    this.intervalId = setInterval(() => {
      this.checkVisible()
    }, 250)
  },
  beforeUnmount () {
    clearInterval(this.intervalId)
  }
}
</script>

<style lang="scss" src="./scrollButton.scss" scoped />
