<template>
  <accountContent title="Авторизация">
    <div class="input-container">
      <form>
        <div v-if="!!formError" class="error">
          {{ formError }}
        </div>

        <div class="x-input-item">
          <div class="input-name">Логин</div>
          <div class="input-content">
            <textInput
              placeholder="example@mail.ru"
              v-bind:defaultValue="email"
              @onChange="onChangeEmail"
            />
          </div>
        </div>
        <div v-if="!!emailErrorText" class="error error-input">
          {{ emailErrorText }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Пароль</div>
          <div class="input-content">
            <textInput
              autocomplete="new-password"
              type="password"
              placeholder="****************"
              v-bind:defaultValue="password"
              @onChange="onChangePassword"
            />
          </div>
        </div>
        <div v-if="!!passwordErrorText" class="error error-input">
          {{ passwordErrorText }}
        </div>
      </form>
    </div>
    <div class="buttons">
      <div class="reg-button">
        <xButton @click="onSubmit">
          Вход
        </xButton>
      </div>
    </div>
  </accountContent>
</template>

<script>
import accountContent from '@/components/accountContent'
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'

const EMAIL_ERROR = 'Укажите корректный e-mail'
const PASSWORD_ERROR = 'Укажите пароль'

export default {
  name: 'accountNew',
  components: {
    accountContent,
    textInput,
    xButton
  },
  data () {
    return {
      email: '',
      emailErrorText: '',
      password: '',
      passwordErrorText: '',
      formError: ''
    }
  },
  methods: {
    onChangeEmail (email) {
      this.email = email
      // eslint-disable-next-line no-useless-escape
      if (email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.emailErrorText = ''
      } else {
        this.emailErrorText = EMAIL_ERROR
      }
    },
    onChangePassword (password) {
      const pass = password.trim()
      this.password = pass
      if (pass) {
        this.passwordErrorText = ''
      } else {
        this.passwordErrorText = PASSWORD_ERROR
      }
    },
    async onSubmit () {
      if (!this.email) {
        this.emailErrorText = EMAIL_ERROR
      }
      if (!this.password) {
        this.passwordErrorText = PASSWORD_ERROR
      }
      if (!this.emailErrorText && !this.passwordErrorText) {
        // login: admin@admin.ru
        // password: 12345678
        const url = window.privateDomen + '/api/oauth/token'
        const code = {
          grant_type: 'password',
          client_id: '3',
          client_secret: 'pSIhCGhmB52VhOqKSL8P4Bw5CmCXYSrnthyce7D7',
          username: this.email,
          password: this.password
        }

        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(code)
        }).then(async response => {
          const data = await response.json()

          if (!response.ok) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          // eslint-disable-next-line
          const { access_token, refresh_token } = data
          localStorage.setItem('access_token', access_token)
          localStorage.setItem('refresh_token', refresh_token)

          this.$store.commit('techStore/doAuth')
          await this.$router.replace({ name: 'private' })
        }).catch(err => {
          this.formError = err
          this.$store.commit('techStore/checkError', { err })
        })
      }
    }
  },
  created () {
    this.$store.commit('techStore/checkLogin', { path: 'private' })
  }
}
</script>

<style lang="scss" src="./accountAdminLogin.scss" scoped />
