<template>
  <div class="activity-header">
    <adminNav>
      <router-link to="/private/clients">
        Клиенты
      </router-link>
      <router-link to="/private/activity/settings">
        Виды деятельности
      </router-link>
      <router-link to="/private/logs">
        Просмотр логов
      </router-link>
      <router-link to="/private/other/settings">
        Прочие настройки
      </router-link>
    </adminNav>
  </div>
  <div class="private-table">
    <div class="msg-table-wrapper">
      <xTable v-bind="tableData" />
    </div>
  </div>
</template>

<script>
import xTable from '@/components/xTable'
import adminNav from '@/components/adminNav'

export default {
  name: 'privateLogs',
  components: {
    xTable,
    adminNav
  },
  data () {
    return {
      tableData: {
        theads: 0,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        align: true,
        privateLogs: true,
        optionsX: [
          { name: 'Дата и время', value: 'logsDate', id: 0 },
          { name: 'Автор', value: 'logsAuthor', id: 1 },
          { name: 'Событие', value: 'logsAction', id: 2 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Дата и время', 'Автор', 'Событие'],
        namesForHeaderX: []
      }
    }
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Кабинет администратора системы')

    fetch(window.host + 'admin_data/logs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.tableData.data = data.data.log_data.map(el => {
        return {
          id: el.id,
          logsDate: new Date(el.created_at).toLocaleDateString('ru-ru', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
          logsAuthor: `${el.user_name}<br /><a href="mailto:${el.user_email}">${el.user_email}</a>`,
          logsAction: el.operation_name
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="../vPrivate/vPrivate.scss" scoped/>
<!--<style lang="scss" src="./vPrivateLogs.scss" scoped />-->
