// Tech
import error from '@/views/error'

// Account
import account from '@/views/account'
import accountAdminLogin from '@/views/accountAdminLogin'

// Private
import vPrivate from '@/views/vPrivate'
import vPrivateCompanies from '@/views/vPrivateCompanies'
import vPrivateActivity from '@/views/vPrivateActivity'
import vPrivateActivitySettings from '@/views/vPrivateActivitySettings'
import vPrivateLogs from '@/views/vPrivateLogs'
import vPrivateOtherSettings from '@/views/vPrivateOtherSettings'
import vPrivateActivityBonuses from '@/views/vPrivateActivityBonuses'
// import vPrivateActivityReferrals from '@/views/vPrivateActivityReferrals'

export default [
  {
    path: '/private',
    redirect: '/private/clients',
    name: 'private',
    component: vPrivate,
    children: [
      {
        path: 'clients',
        component: vPrivateCompanies
      },
      {
        path: '/private/other',
        component: vPrivateOtherSettings,
        redirect: '/private/other/settings',
        children: [
          {
            path: 'settings',
            component: vPrivateOtherSettings
          }
        ]
      },
      {
        path: 'activity',
        component: vPrivateActivity,
        redirect: '/private/activity/settings',
        children: [
          {
            path: 'settings',
            component: vPrivateActivitySettings
          },
          {
            path: 'bonuses',
            component: vPrivateActivityBonuses
          },
          {
            path: 'bonuses/:id',
            component: vPrivateActivityBonuses
          }
          // {
          //   path: 'referrals',
          //   component: vPrivateActivityReferrals
          // }
          // {
          //   path: 'create',
          //   component: vPrivateActivityCreate,
          //   redirect: '/private/activity/create/bonuses',
          //   children: [
          //     {
          //       path: 'bonuses',
          //       component: vPrivateActivityBonuses
          //     },
          //     {
          //       path: 'referrals',
          //       component: vPrivateActivityReferrals
          //     }
          //   ]
          // }
        ]
      },
      {
        path: 'logs',
        component: vPrivateLogs
      }
    ]
  },
  {
    path: '/',
    redirect: '/login',
    component: account,
    children: [
      {
        path: '/login',
        component: accountAdminLogin,
        name: 'login'
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: error
  }
]
