<template>
  <adminHeader :vPrivate="true" :titleOld="titleOld" :loading="loading" />
  <div class="private_body">
    <div class="container">
      <router-view @titleName="handleName" />
    </div>
  </div>
  <save-popup/>
</template>

<script>
import adminHeader from '@/components/adminHeader'
import savePopup from '@/components/savePopup'

export default {
  name: 'private',
  components: {
    savePopup,
    adminHeader
  },
  data () {
    return {
      titleOld: '',
      loading: false
    }
  },
  methods: {
    handleName (name) {
      if (name === this.titleOld) {
        this.loading = false
      } else {
        this.loading = true
        setTimeout(function (scope) {
          scope.loading = false
        }, 100, this)
      }
      this.titleOld = name
      this.$store.commit(
        'techStore/setTitleName',
        {
          name
        }
      )
    }
  }
}
</script>

<style lang="scss" src="./vPrivate.scss" scoped />
