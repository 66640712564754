<template>
  <header class="header" :class="{'header-private': vPrivate}">
    <div class="header__title-container">
      <transition appear name="title-changer">
        <h2 v-if="loading" key="newPage" class="header__title"></h2>
        <!--        <div v-if="titleArrow" class="header__arrow">-->
        <!--          <icon name="arrow"/>-->
        <!--          <h2 class="header__title header__title-new">Владимир Богдановский</h2>-->
        <!--        </div>-->
        <h2 v-else key="oldPage" class="header__title header__title-new">{{ titleOld }}</h2>
      </transition>
    </div>
    <div v-if="vPrivate" class="header__info">
      <a href="#" class="header-pop-item" @click="this.$store.commit('techStore/logout')">
        <icon name="logout" class="header-pop-item-icon header-pop-item-icon-close" />
        Выход
      </a>
    </div>
    <div v-if="!vPrivate" class="header__info">
      <div class="header__subscribe" :class="headData.subscription <= 5 ? 'red_sub' : ''">
        До окончания подписки
        <span class="header__subscribe-link">
          {{ headData.subscription_text }}
          <note icon-size="note-big" note-position="bottom-to-right">
            <b>
              Для продления подписки обратитесь к вашему личному менеджеру.
            </b>
          </note>
        </span>
      </div>
      <router-link to="/admin/settings/" class="header__client">
        <div class="header__client-logo-container">
          <img v-if="!this.$store.state.techStore.companyLogo" src="@/assets/img/client-logo.png" :alt="headData.company" class="header__client-logo">
          <img v-if="this.$store.state.techStore.companyLogo" :src="getCompanyLogo" :alt="headData.company" class="header__client-logo">
        </div>
        <div class="header__client-desc">
          <div class="header__client-name">{{ this.$store.state.techStore.companyName }}</div>
          <div class="header__client-role">Администратор</div>
        </div>
      </router-link>
      <div @click="goPop" class="header__menu">
        <toggler />
        <pop-up :open="popUp" @close="goPop" :center="false" :icon-close="false" additionalClass="r-250">
          <router-link to="/admin/settings" class="header-pop-item">
            <icon name="manager" class="header-pop-item-icon" />
            Профиль компании
          </router-link>
          <router-link to="/instruction" class="header-pop-item">
            <icon name="instruction" class="header-pop-item-icon" />
            Видеоинструкция
          </router-link>
          <a href="#" class="header-pop-item" @click="this.$store.commit('techStore/logout')">
            <icon name="logout" class="header-pop-item-icon header-pop-item-icon-close" />
            Выход
          </a>
        </pop-up>
      </div>
    </div>
    <div
      class="header__mobile-menu"
      :class="this.$store.state.techStore.isSidebarOpened && 'active'"
      @click="this.$store.commit('techStore/toggleSidebar')"
    >
      <div class="header__mobile-menu-item"></div>
      <div class="header__mobile-menu-item"></div>
      <div class="header__mobile-menu-item"></div>
      <div class="header__mobile-menu-item"></div>
    </div>
  </header>
</template>

<script>
import toggler from '@/components/toggler'
import note from '@/components/note'
import popUp from '@/components/popUp'

export default {
  name: 'adminHeader',
  components: {
    toggler,
    note,
    popUp
  },
  props: {
    titleOld: {
      type: String
    },
    loading: {
      type: Boolean
    },
    vPrivate: {
      type: Boolean
    }
  },
  data () {
    return {
      headData: {
        active: true,
        company: '─',
        subscription: 1,
        subscription_text: '1 день'
      },
      titleChanger: false,
      titleArrow: false,
      popUp: false
    }
  },
  watch: {
    $route: function (to, from) {
      this.popUp = false
    }
  },
  computed: {
    getCompanyLogo () {
      return window.domen + this.$store.state.techStore.companyLogo
    }
  },
  methods: {
    // animateArrow () {
    //   this.titleArrow = !this.titleArrow
    // }
    goPop () {
      this.popUp = !this.popUp
    }
  },
  created () {
    if (!this.vPrivate) {
      fetch(window.host + 'users/header', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.headData = data.data

        if (this.headData.subscription <= 0) {
          this.$store.commit(
            'popupStore/show',
            {
              title: 'Срок подписки истек',
              blockClose: true,
              useFullWindow: true,
              useMiddleTitle: true
            }
          )
        }

        this.$store.commit(
          'techStore/setCompanyData',
          {
            path: data.data.logotype,
            name: data.data.company,
            id: data.data.id
          }
        )

        this.$store.commit(
          'techStore/setMessagesCount',
          {
            counter: data.data.messages
          }
        )

        this.$store.commit(
          'techStore/setPurchasesCount',
          {
            counter: data.data.purchases
          }
        )

        if (data.data.id) {
          this.$store.commit('socketStore/subscribe', {
            channel: `private-notice.${data.data.id}`,
            eventName: 'App\\Events\\Notices',
            callback: socketData => {
              this.$store.commit(
                'techStore/setMessagesCount',
                {
                  counter: socketData.messages
                }
              )

              this.$store.commit(
                'techStore/setPurchasesCount',
                {
                  counter: socketData.purchases
                }
              )
            }
          })
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  unmounted () {
    this.$store.commit(
      'socketStore/unsubscribe',
      {
        channel: `private-notice.${this.$store.state.techStore.companyId}`
      }
    )
  }
}

</script>

<style lang="scss" src="./adminHeader.scss" scoped/>
