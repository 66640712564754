<template>
  <router-view />
</template>

<script>

export default {
  name: 'privateActivity',
  emits: ['titleName'],
  watch: {
    $route (to, from) {
      this.setTitle()
    }
  },
  methods: {
    setTitle () {
      const id = typeof this.$router.currentRoute.value.params.id !== 'undefined' ? this.$router.currentRoute.value.params.id : false
      const title = id ? 'Редактирование вида деятельности' : 'Создание нового вида деятельности'
      this.$emit('titleName', title)
    }
  },
  created () {
    this.setTitle()
  }
}
</script>

<style lang="scss" src="./vPrivateActivity.scss" scoped />
