<template>
  <div class="note-container" :class="[iconSize]">
    <div class="note-content">
      <div class="note-icon-wrap">
        <icon class="note-icon" name="note"/>
      </div>
      <div :class="[textAlign, notePosition]" class="note-popup">
        <div class="note-popup-container">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'note',
  props: {
    textAlign: {
      validator (value) {
        return ['text-left', 'text-center'].includes(value)
      }
    },
    notePosition: {
      validator (value) {
        return ['bottom-to-left', 'bottom-to-right'].includes(value)
      }
    },
    iconSize: {
      validator (value) {
        return ['note-small', 'note-big'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" src="./note.scss" scoped/>
